import React from 'react'
import { Link } from 'react-router-dom'


export const LoginFallido = () => {
  return (
    <div>
        <div>
    <h1>Error</h1>
    <Link to="/">Volver al inicio</Link>
  </div>
    </div>
  )
}
